import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input()isAboutRouteActive: boolean = false;
  @Input()isContactRouteActive: boolean = false;
  @Input()isResumeRouteActive: boolean = false;
  @Input()isProjectsRouteActive: boolean = false;
  constructor() {
  }
  ngOnInit(): void {
  }
}
