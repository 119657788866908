import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  careerSummary: string[] = [
    "Extensive experience on building and sustaining core reusable technology that would ensure scalability, reliability, availability and resiliency for the products running on the platform and power the enterprise's quest to remain technologically competitive.",
    "Experienced in developing and maintaining production web or hybrid mobile applications.",
    "Proven experience on Micro-service and Micro-frontend Architectures.",
    "Experienced in developing predictive models using supervised and unsupervised machine learning/deep learning.",
    "Having good experience in implementation and delivering CI/CD for the product suites",
    "Having good working knowledge on SLOs/SLIs. Involved in doing RCAs(Root Cause Analysis), troubleshooting, remediation.",
    "Having good experience in Architecting and developing customer applications to be cloud(AWS,Google,Azure) optimized.",
    '“Automation First” mindset and passion towards Devops and SRE',
    "Proven experience of leading a team of architects and developers, managing and mentoring them."
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
