import { Component, OnInit, PLATFORM_ID, Inject  } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd  } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'personal-site';
  isAboutRouteActive: boolean = false;
  isContactRouteActive: boolean = false;
  isResumeRouteActive: boolean = false;
  isProjectsRouteActive: boolean = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, router: Router, private meta: Meta, private seoTitle: Title) {
    this.meta.addTags([
      {name: 'description', content: 'Rajaram Ganjikunta Portfolio'},
      {name: 'author', content: 'Rajaram Ganjikunta'},
      {name: 'keywords', content: 'Chief Technical Officer, Rajaram Ganjikunta, Architect, Fullstack, DevOps'}
    ]);
    this.seoTitle.setTitle('Rajaram Ganjikunta');
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log(event.url);
        this.isAboutRouteActive = (event.url.indexOf('about') >= 0 || event.url === '/');
        this.isContactRouteActive = event.url.indexOf('contact') >= 0;
        this.isResumeRouteActive = event.url.indexOf('resume') >= 0;
        this.isProjectsRouteActive = event.url.indexOf('projects') >= 0;
      }
      if (isPlatformBrowser(this.platformId)) {
        router.events.subscribe((event: NavigationEnd) => {
          window.scroll(0, 0);
        });
      }
    });
  }
  ngOnInit(): void {
  }
}
