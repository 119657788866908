<article class="resume-wrapper text-center position-relative">
    <div class="resume-wrapper-inner mx-auto text-left bg-white shadow-lg">
        <header class="resume-header pt-4 pt-md-0">
            <div class="media flex-column flex-md-row">
                <div class="media-body p-4 d-flex flex-column flex-md-row mx-auto mx-lg-0">
                    <div class="primary-info">
                        <h1 class="name mt-0 mb-1 text-white text-uppercase text-uppercase">Rajaram G</h1>
                        <div class="title mb-3">Full Stack Developer / ML Practitioner</div>
                        <ul class="list-unstyled">
                            <li class="mb-2"><a href="#"><i class="far fa-envelope fa-fw mr-2" data-fa-transform="grow-3"></i>reach2rajaram@gmail.com</a></li>
                            <li><a href="#"><i class="fas fa-mobile-alt fa-fw mr-2" data-fa-transform="grow-6"></i>+91-9505486423</a></li>
                        </ul>
                    </div>
                    <!--//primary-info-->
                    <div class="secondary-info ml-md-auto mt-2">
                        <ul class="resume-social list-unstyled">
                            <li class="mb-3"><a href="https://www.linkedin.com/in/rajaramganjikunta" target="_blank"><span class="fa-container text-center mr-2"><i class="fab fa-linkedin-in fa-fw"></i></span>linkedin.com/in/rajaramganjikunta</a></li>
                            <li><a href="http://rajaramg.com/" target="_blank"><span class="fa-container text-center mr-2"><i class="fas fa-globe"></i></span>http://rajaramg.com/</a></li>
                        </ul>
                    </div>
                    <!--//secondary-info-->

                </div>
                <!--//media-body-->
            </div>
            <!--//media-->
        </header>
        <div class="resume-body p-5">
            <app-summary></app-summary>
            <div class="row">
                <div class="col-lg-9">
                    <app-work-experience></app-work-experience>
                </div>
                <div class="col-lg-3">
                    <section class="resume-section education-section mb-5">
                        <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Education</h2>
                        <div class="resume-section-content">
                            <ul class="list-unstyled">
                                <li class="mb-2">
                                    <div class="resume-degree font-weight-bold">MS in Software Systems</div>
                                    <div class="resume-degree-org">BITS, Pilani</div>
                                    <div class="resume-degree-time">2007 - 2009</div>
                                </li>
                                <li>
                                    <div class="resume-degree font-weight-bold">B.Tech. in ECE</div>
                                    <div class="resume-degree-org">JNTU, Hyderabad</div>
                                    <div class="resume-degree-time">2001 - 2005</div>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section class="resume-section reference-section mb-5">
                        <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Certifications</h2>
                        <div class="resume-section-content">
                            <ul class="list-unstyled resume-awards-list">
                                <li class="mb-2 pl-4 position-relative">
                                    <i class="resume-award-icon fas fa-trophy position-absolute" data-fa-transform="shrink-2"></i>
                                    <div class="resume-award-name">Cloudera Certified Developer for Apache Hadoop (CCDH)</div>
                                </li>
                                <li class="mb-0 pl-4 position-relative">
                                    <i class="resume-award-icon fas fa-trophy position-absolute" data-fa-transform="shrink-2"></i>
                                    <div class="resume-award-name">MCSD (Microsoft Certified Solution Developer) in Web Applications</div>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section class="resume-section language-section mb-5">
                        <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Language</h2>
                        <div class="resume-section-content">
                            <ul class="list-unstyled resume-lang-list">
                                <li class="mb-2 align-middle"><span class="resume-lang-name font-weight-bold">English</span> <small class="text-muted font-weight-normal">(Professional)</small></li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
            <!--//row-->
        </div>
        <!--//resume-body-->


    </div>
</article>