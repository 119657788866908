import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.css']
})
export class WorkExperienceComponent implements OnInit {

  experienceSummary: any[] = [
    {
      companyName: 'Freelance',
      duration: 'Oct 2020 - Present',
      designation: 'Solution Architect',
      roles: [
        'Architecting highly scalable and reliable cloud solutions by mapping business problems to end-to-end technology solutions.',
        'Building products for global and emerging markets and on emerging technology platforms.',
        'Deliver key research (MVP, POC) with an efficient turn-around time to help make strong product decisions.',
        'Design and code highly scalable, machine learning applications processing large volumes of data to solve interesting business problems.',
        'Driving the DevOps mindset and SRE culture withing the team.',
      ],
      technologies: [
        'Hadoop',
        'Python',
        'Numpy',
        'Pandas',
        'Scikit-learn',
        'TensorFlow',
        'PyTorch',
        'Keras',
        'Scipy',
        'Visual Studio 2019',
        'MERN Stack',
        'MEAN Stack',
        'Redux',
        'Go',
        'SQL Server',
        'MongoDB',
        'React Native',
        'Ionic Framework',
        'AWS',
        'GCP',
        'Azure',
        'BlockChain',
        'UiPath',
        'Robotic Process Automation (RPA)',
        'Solidity',
        'Ethereum',
        'Storytelling',
        'Site Reliability Engineering'
      ]
    },
    {
      companyName: 'ACubetech Solutions Private limited',
      duration: 'Feb 2014 - Sep 2020',
      designation: 'Chief Technical Architect',
      roles: [
        'Design to build for high performance on web and mobile platforms following best practices and standards established in the industry.',
        'Responsible for break downing project into tasks and provide accurate estimates.',
        'Deliver features in an end-to-end manner: technical design, development, testing, deployment and maintenance.',
        'Responsible for design and build scalable SOA / Microservices',
        'Responsible for data modeling and database design',
        'Own the development, build and releases of software modules to multiple deployment environments',
        'Provide technical and domain thought-leadership and mentoring to other members of the team.',
        'Define, Measure and improve Reliability Metrics (SLO/SLI), Observability (Monitoring, Logging- Tracing solutions), Ops process (Incident, Problem Mgmt) and streamline – automate release management.'
      ],
      technologies: [
        'Visual Studio 2019',
        'AngularJS/Angular',
        'React.js',
        'Redux',
        'Node.js',
        'Express.js',
        'NestJS',
        'Go',
        '.Net Core',
        'ASP.Net WebAPI 2',
        'WCF',
        'ASP.Net',
        'Datadog',
        'TeamCity',
        'SQL Server',
        'MongoDB',
        'AWS',
        'Datadog',
        'DevOps'
      ]
    }, 
    {
      companyName: 'Samsung R&D Institute India',
      duration: 'Sep 2013 - Feb 2014',
      designation: 'Technical Lead',
      roles: [
        'Understand business objectives and apply appropriate Machine Learning(ML) techniques to solve business problems.',
        'Designing, developing and augmenting ML models and algorithms',
        'Deliver reliable solutions that handle massive data using Big Data Architectures',
        'Quickly build functional prototypes to solve internal and external use-cases',
      ],
      technologies: [
        'Hadoop',
        'Python',
        'Numpy',
        'Pandas',
        'Scikit-learn'
      ]
    },
    {
      companyName: 'Sony India Software Centre',
      duration: 'Mar 2011 - Sep 2013',
      designation: 'Technical Lead',
      roles: [
        'Actively participate in all phases of software development lifecycle: analysis, technical design, planning, development, testing/CICD, release, post production/escalation support.',
        'Responsible for Peer Code Reviews',
        'Drive the adaptation of Continuous Integration and Continuous Development principles by the team.',
        'Assisting in troubleshooting complex technical problems in development and production',
      ],
      technologies: [
        'Visual Studio 2010',
        'C#','WCF','WPF','MVVM','Spreadsheet XML','SpreadsheetGear','NUnit',
        'StyleCop', 'FxCop 1.36', 'VBA(Excel)', 'XML', 'TFS', 'SQL Server'
      ]
    },
    {
      companyName: 'HCL Technologies',
      duration: 'Jun 2009 - Feb 2011',
      designation: 'Senior Software Engineer',
      roles: [
        'Involved in Requirement Analysis and designing High-Level Design (HLD) and Low Level Design (LLD).',
        'Responsible for basic database design',
        'Understand performance parameters and assess application performance.',
        'Participated in design and code reviews with the team.',
        'Responsible for Automating/Customizing MS Word and MS Excel using VBA.',
        'Responsible for writing unit and integration tests to improve and maintain high code coverage',
      ],
      technologies: [
        'Visual Studio 2008',
        'VB.Net', 'C#',
        'Smart Document SDK', 'FxCop 1.36',
        'CAPCO Framework', 
        'XML', 'XSD', 'XSLT', 'RAML', 'WORDML',
        'VBA(Word, Excel)',
        'WinCVS', 'NUnit', 'CruiseControl.NET'
      ]
    },
    {
      companyName: 'Zenith Software LTD',
      duration: 'Sep 2008 - May 2009',
      designation: 'Software Engineer',
      roles: [
        'Involved in Requirements Gathering, Analysis, Technical Design and Coding.',
        'Contributed to the development of APIs and integrating with UI',
        'Responsible for integration of payment solutions',
        'Responsible for automatation of manual tasks on daily basis',
        'Developed MSI setup packages for the product and database.',
        'Provided technical guidance to junior team members.'
      ],
      technologies: [
        'Visual Studio 2008',
        'C#.NET',
        'JavaScript',
        'jQuery',
        'XML',
        'XSLT',
        'AJAX',
        'Web Services',
        'Windows Services',
        'SQL Server 2008',
        'PayPal',
        'SMO Framework'
      ]
    },
    {
      companyName: 'Oak Labs India Private Limited',
      duration: 'Dec 2006 - Aug 2008',
      designation: 'Software Engineer',
      roles: [
        "Worked with SW lead engineer and SW architect to translate user inputs to software requirements and design specifications.",
        "Participated in all phases of software development: requirements, design, construction, testing, deployment, and maintenance",
        "Development and optimization of database objects using SQL Server (Views, Stored Procedures and Functions).",
        "Build reusable code and libraries for future use",
      ],
      technologies: [
        'Visual Studio 2005',
        'C#.NET',
        'JavaScript',
        'XML',
        'XSLT',
        'WCF',
        'Web Service Software Factory(WSSF)',
        'SQL Server 2005',
        'SQL Server Reporting Services',
        'Enterprise Library 2.0',
        'VSTO(Visual Studio Tools for Office)',
        'TortoiseSVN',
        'Visual SourceSafe (VSS) 2005'
      ]
    },
    {
      companyName: 'Wipro Infotech',
      duration: 'Mar 2006 - Dec 2006',
      designation: 'Software Trainee',
      roles: [
        'Designing and building mockups, prototypes, or wireframes for front-end solutions.',
        'Designing and building back-end data models for services',
        'Executing unit tests and performing basic systems testing.',
        'Creating or updating technical documentation and test cases.',
        'Written Stored Procedures and Functions using SQL Server.'
      ],
      technologies: [
        'Visual Studio 2003',
        'ASP.Net 1.1',
        'ADO.Net 1.1',
        'VB.Net',
        'JavaScript',
        'XML',
        'Crystal Reports',
        'Web Services',
        'SQL Server'
      ]
    }
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
