<section class="resume-section experience-section mb-5">
    <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Work Experience</h2>
    <div class="resume-section-content">
        <div class="resume-timeline position-relative">
            <article class="resume-timeline-item position-relative pb-5" *ngFor="let experience of experienceSummary">

                <div class="resume-timeline-item-header mb-2">
                    <div class="d-flex flex-column flex-md-row">
                        <h3 class="resume-position-title font-weight-bold mb-1">{{experience.designation}}</h3>
                        <div class="resume-company-name ml-auto">{{experience.companyName}}</div>
                    </div>
                    <!--//row-->
                    <div class="resume-position-time">{{experience.duration}}</div>
                </div>
                <!--//resume-timeline-item-header-->
                <div class="resume-timeline-item-desc">
                    <ul>
                        <li *ngFor="let roleInfo of experience.roles;">{{roleInfo}}</li>
                    </ul>
                    <h4 class="resume-timeline-item-desc-heading font-weight-bold">Technologies used:</h4>
                    <ul class="list-inline">
                        <li class="list-inline-item" *ngFor="let technologyInfo of experience.technologies">
                            <span class="badge badge-secondary badge-pill">{{technologyInfo}}</span>
                        </li>
                    </ul>
                </div>
                <!--//resume-timeline-item-desc-->

            </article>
            <!--//resume-timeline-item-->
        </div>
        <!--//resume-timeline-->
    </div>
</section>