<section class="resume-section skills-section mb-5">
    <h2 class="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Skills &amp; Tools</h2>
    <div class="resume-section-content">
        <div class="resume-skill-item" *ngFor="let skillInfo of skillsSummary">
            <h4 class="resume-skills-cat font-weight-bold">{{skillInfo.category}}</h4>
            <ul class="list-unstyled" *ngFor="let techInfo of skillInfo.technologies">
                <li class="mb-2">
                    <div class="resume-skill-name">{{techInfo.name}}</div>
                    <div class="progress resume-progress">
                        <div class="progress-bar theme-progress-bar-dark" role="progressbar" [style]="'width: '+techInfo.rating+'%'" [aria-valuenow]="techInfo.rating" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="resume-skill-item">
            <h4 class="resume-skills-cat font-weight-bold">Others</h4>
            <ul class="list-inline">
                <li class="list-inline-item" *ngFor="let otherSkill of otherSkills"><span class="badge badge-light">{{otherSkills}}</span></li>
            </ul>
        </div>
    </div>
</section>