import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {

  skillsSummary: any[] = [
    {
      category: 'Frontend',
      technologies: [
        {
          name: 'Angular/React',
          rating: 20,
        },
        {
          name: 'ReactNative/Ionic',
          rating: 40,
        }
      ]
    },
    {
      category: 'Backend',
      technologies: [
        {
          name: 'MongoDB',
          rating: 80,
        },
        {
          name: 'SQL Server',
          rating: 10,
        }
      ]
    }
  ];

  otherSkills: string[] = [
    'DevOps',
    'Git'
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
