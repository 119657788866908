<div class="top-bar theme-bg-primary-darken">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-dark position-relative">
            <ul class="social-list list-inline mb-0">
                <li class="list-inline-item">
                    <a class="text-white" href="https://www.linkedin.com/in/rajaramganjikunta/" target="_blank">
                        <i class="fab fa-linkedin-in fa-fw"></i>
                    </a>
                </li>
            </ul>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse text-uppercase" id="navigation">
                <ul class="navbar-nav ml-lg-auto">
                    <li class="nav-item mr-lg-3" [ngClass]="{'active' : isAboutRouteActive}">
                        <a class="nav-link" [routerLink]="['/about']">About <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item mr-lg-3" [ngClass]="{'active' : isProjectsRouteActive}">
                        <a class="nav-link" [routerLink]="['/projects']">Projects</a>
                    </li>
                    <li class="nav-item mr-lg-3" [ngClass]="{'active' : isResumeRouteActive}">
                        <a class="nav-link" [routerLink]="['/resume']" href="#">Resume</a>
                    </li>
                    <li class="nav-item mr-lg-3" [ngClass]="{'active' : isContactRouteActive}">
                        <a class="nav-link" [routerLink]="['/contact']">Contact</a>
                    </li>
                </ul>
                <span id="slide-line"></span>
            </div>
        </nav>

    </div>
</div>