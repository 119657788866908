import { Component, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private meta: Meta, private seoTitle: Title) {
    this.meta.addTags([
      {name: 'description', content: 'Rajaram Ganjikunta Portfolio'},
      {name: 'author', content: 'Rajaram Ganjikunta'},
      {name: 'keywords', content: 'Chief Technical Officer, Rajaram Ganjikunta, Architect, Fullstack, DevOps'}
    ]);
    this.seoTitle.setTitle('Contact');
  }

  ngOnInit(): void {
  }

}
