<section class="skills-section section py-5">
    <div class="container">
        <h3 class="section-title font-weight-bold text-center mb-3">Skills Overview</h3>
        <div class="section-intro mx-auto text-center mb-5 text-secondary">
            I have more than 13 years' experience building rich web and hybrid mobile applications for clients all over the world. Having proven success in building successful algorithms & models for different industries. Below is a quick overview of my main technical
            skill sets and tools I use. Want to find out more about my experience?
            <a [routerLink]="['/resume']">Check out my resume</a>.
        </div>
        <div class="skills-blocks mx-auto pt-5">
            <div class="row">
                <div class="skills-block col-12 col-lg-4 mb-5 mb-3 mb-lg-0">
                    <div class="skills-block-inner bg-white shadow-sm py-4 px-5 position-relative">
                        <h4 class="skills-cat text-center mb-3 mt-5">Frontend</h4>
                        <div class="skills-icon-holder position-absolute d-inline-block rounded-circle text-center">
                            <img class="skills-icon" src="./assets/images/frontend-icon.svg">
                        </div>
                        <ul class="skills-list list-unstyled text-secondary">
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>React</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>Angular</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>React Native/Ionic</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>ASP.Net MVC</li>
                        </ul>
                    </div>
                    <!--//skills-block-inner-->
                </div>
                <!--//skills-block-->

                <div class="skills-block col-12 col-lg-4 mb-5 mb-3 mb-lg-0">
                    <div class="skills-block-inner bg-white shadow-sm py-4 px-5 position-relative">
                        <h4 class="skills-cat text-center mb-3 mt-5">Middle Tier</h4>
                        <div class="skills-icon-holder position-absolute d-inline-block rounded-circle text-center">
                            <span style="color: white; font-size: 25px;">&#123; API &#125;</span>
                        </div>
                        <ul class="skills-list list-unstyled text-secondary">
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>Node.js/Express.js/NestJS</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>Go/Python</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>WCF Services</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>ASP.Net WebAPI 2</li>
                        </ul>
                    </div>
                    <!--//skills-block-inner-->
                </div>
                <!--//skills-block-->

                <div class="skills-block col-12 col-lg-4">
                    <div class="skills-block-inner bg-white shadow-sm py-4 px-5 position-relative">
                        <h4 class="skills-cat text-center mb-3 mt-5">Backend</h4>
                        <div class="skills-icon-holder position-absolute d-inline-block rounded-circle text-center">
                            <img class="skills-icon" src="./assets/images/backend-icon.svg">
                        </div>
                        <ul class="skills-list list-unstyled text-secondary">
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>Microsoft SQL Server</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>PostgresSQL</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>MongoDB</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>HBase</li>
                        </ul>
                    </div>
                    <!--//skills-block-inner-->
                </div>
                <!--//skills-block-->

            </div>
            <!--//row-->
        </div>
    </div>
    <!--//container-->
</section>
<!--//skills-section-->

<section class="skills-section section py-5">
    <div class="container">
        <div class="skills-blocks mx-auto pt-5">
            <div class="row">
                <div class="skills-block col-12 col-lg-4 mb-5 mb-3 mb-lg-0">
                    <div class="skills-block-inner bg-white shadow-sm py-4 px-5 position-relative">
                        <h4 class="skills-cat text-center mb-3 mt-5">Operational</h4>
                        <div class="skills-icon-holder position-absolute d-inline-block rounded-circle text-center">
                            <span style="color: white; font-size: 30px;">Ops</span>
                        </div>
                        <ul class="skills-list list-unstyled text-secondary">
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>DevOps</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>Site Reliability Engineering</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>CI/CD Pipelines</li>
                        </ul>
                    </div>
                    <!--//skills-block-inner-->
                </div>
                <!--//skills-block-->
                <div class="skills-block col-12 col-lg-4 mb-5 mb-3 mb-lg-0">
                    <div class="skills-block-inner bg-white shadow-sm py-4 px-5 position-relative">
                        <h4 class="skills-cat text-center mb-3 mt-5">Cloud Platforms</h4>
                        <div class="skills-icon-holder position-absolute d-inline-block rounded-circle text-center">
                            <img class="skills-icon" src="./assets/images/cloud.svg">
                        </div>
                        <ul class="skills-list list-unstyled text-secondary">
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>Google Cloud Platform</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>AWS Cloud Platform</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>Windows Azure</li>
                        </ul>
                    </div>
                    <!--//skills-block-inner-->
                </div>
                <!--//skills-block-->
                <div class="skills-block col-12 col-lg-4">
                    <div class="skills-block-inner bg-white shadow-sm py-4 px-5 position-relative">
                        <h4 class="skills-cat text-center mb-3 mt-5">Others</h4>
                        <div class="skills-icon-holder position-absolute d-inline-block rounded-circle text-center">
                            <img class="skills-icon" src="./assets/images/other-skills-icon.svg">
                        </div>
                        <ul class="skills-list list-unstyled text-secondary">
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>Robotic Process Automation</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>Agile & Scrum</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>AI & Machine Learning</li>
                            <li class="mb-2"><i class="fas fa-check mr-2 text-primary"></i>Blockchain</li>
                        </ul>
                    </div>
                    <!--//skills-block-inner-->
                </div>
                <!--//skills-block-->

            </div>
            <!--//row-->
        </div>
    </div>
    <!--//container-->
</section>
<!--//skills-section-->

<section class="section-proof section bg-white py-5">
    <div class="container">
        <h3 class="section-title font-weight-bold text-center mb-5">Great clients I've worked with</h3>
        <div class="logos row mb-5">
            <div class="logo px-3 col-6 col-md-4 col-lg-2 mr-0 px-md-5 px-lg-4">
                <a class="logo-link" [routerLink]="['/projects']"><img class="img-fluid svg-ie-fix" src="./assets/images/logos/Wipro.png"></a>
            </div>
            <div class="logo px-3 col-6 col-md-4 col-lg-2 mr-0 px-md-5 px-lg-4">
                <a class="logo-link" [routerLink]="['/projects']"><img class="img-fluid svg-ie-fix" src="./assets/images/logos/zenith.png"></a>
            </div>
            <div class="logo px-3 col-6 col-md-4 col-lg-2 mr-0 px-md-5 px-lg-4">
                <a class="logo-link" [routerLink]="['/projects']"><img class="img-fluid svg-ie-fix" src="./assets/images/logos/hcl.png"></a>
            </div>
            <div class="logo px-3 col-6 col-md-4 col-lg-2 mr-0 px-md-5 px-lg-4">
                <a class="logo-link" [routerLink]="['/projects']"><img class="img-fluid svg-ie-fix" src="./assets/images/logos/sony.png"></a>
            </div>
            <div class="logo px-3 col-6 col-md-4 col-lg-2 mr-0 px-md-5 px-lg-4">
                <a class="logo-link" [routerLink]="['/projects']"><img class="img-fluid svg-ie-fix" src="./assets/images/logos/Samsung.png"></a>
            </div>
            <div class="logo px-3 col-6 col-md-4 col-lg-2 mr-0 px-md-5 px-lg-4">
                <a class="logo-link" [routerLink]="['/projects']"><img class="img-fluid svg-ie-fix" src="./assets/images/logos/acube.svg"></a>
                <a class="logo-link" [routerLink]="['/projects']"><img class="img-fluid svg-ie-fix" src="./assets/images/logos/RxNT.png"></a>
            </div>
        </div>

    </div>

</section>

<section class="section-cta py-5 bg-primary text-white">
    <div class="container">
        <div class="text-center">
            <img class="profile-small d-inline-block mx-auto rounded-circle mb-2" src="./assets/images/profile.jpg" alt="">
        </div>
        <h3 class="section-title font-weight-bold text-center mb-2">Interested in hiring me for your project?</h3>
        <div class="section-intro mx-auto text-center mb-3">
            Looking for an experienced full-stack developer / ML Practitioner? To start an initial chat, just drop me an email at <a class="link-on-bg" href="#">reach2rajaram@gmail.com</a>.
        </div>
        <div class="text-center">
            <a class="theme-btn-on-bg btn" [routerLink]="['/contact']">Let's Talk</a>
        </div>
    </div>
    <!--//container-->
</section>