<section class="section pt-5">
    <div class="container proj-container">
        <div class="masthead">
            <div>
                <h1 class="mb-3">Coming Soon!</h1>
                <p class="mb-5">We're working hard to finish the development of this site.
                    <strong>Stay tuned !!!!</strong></p>
            </div>
        </div>
    </div>
</section>