<section class="section py-5">
    <div class="container">
        <div class="row">
            <div class="contact-intro col-lg-8 mx-lg-auto mb-5 text-center">
                <img class="profile-small d-inline-block mx-auto rounded-circle mb-3" src="./assets/images/profile.jpg" alt="">
                <div class="speech-bubble bg-white p-4 p-lg-5 shadow-sm">
                    <p class="text-left mb-3">I'm currently taking on freelance work. If you are interested in hiring me for your project please use the form below to get in touch. Want to know how I work and what I can offer? Check out my <a [routerLink]="['/projects']">project case studies</a>                        and <a [routerLink]="['/resume']">resume</a>.</p>
                    <p class="text-left mb-3">
                        To start an initial chat, just drop me an email at <a class="link-on-bg" href="#">reach2rajaram@gmail.com</a>.</p>
                    <h6 class="font-weight-bold text-center mb-3">You can also find me on</h6>
                    <ul class="social-list-color list-inline mb-0">
                        <li class="list-inline-item mb-3"><a class="linkedin" href="https://www.linkedin.com/in/rajaramganjikunta" target="_blank"><i
                            class="fab fa-linkedin-in fa-fw"></i></a></li>
                    </ul>
                    <!--//social-list-->
                </div>

            </div>
            <!--//contact-intro-->
        </div>
        <!--//row-->
    </div>
    <!--//container-->
</section>
<!--//section-->