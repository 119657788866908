<header class="header">
    <app-header [isAboutRouteActive]="isAboutRouteActive" [isContactRouteActive]="isContactRouteActive" [isResumeRouteActive]="isResumeRouteActive" [isProjectsRouteActive]="isProjectsRouteActive"></app-header>
    <div *ngIf="isAboutRouteActive" class="header-intro theme-bg-primary text-white py-5">
        <div class="container">
            <div class="profile-teaser media flex-column flex-md-row">
                <img class="profile-image mb-3 mb-md-0 mr-md-5 ml-md-0 rounded mx-auto" src="./assets/images/profile.jpg" alt="">
                <div class="media-body text-center text-md-left">
                    <div class="lead">Hello, my name is</div>
                    <h2 class="mt-0 display-4 font-weight-bold">Rajaram Ganjikunta</h2>
                    <div class="bio mb-3">I'm a full-stack developer(Web and Hybrid mobile apps) and AI-ML(Artificial Intelligence and Machine Learning) practitioner. Want to know how I may help your project? Check out my project <a class="link-on-bg" [routerLink]="['/projects']">case studies</a>                        and <a class="link-on-bg" [routerLink]="['/resume']">resume</a>.
                    </div>
                    <!--//bio-->
                    <a class="theme-btn-on-bg btn font-weight-bold theme-btn-cta" [routerLink]="['/contact']">Hire Me</a>

                </div>
                <!--//media-body-->
            </div>
            <!--//profile-teaser-->

        </div>
        <!--//container-->
    </div>
    <!--//header-intro-->

    <div *ngIf="isProjectsRouteActive" class="header-intro theme-bg-primary text-white py-5">
        <div class="container">
            <h2 class="page-heading mb-3">Projects</h2>
            <div class="page-heading-tagline">In-depth Case Studies to show you what I can offer and how I work</div>
        </div>
    </div>

    <div *ngIf="isContactRouteActive" class="header-intro theme-bg-primary text-white py-5">
        <div class="container">
            <h2 class="page-heading mb-0">Get In Touch</h2>
        </div>
    </div>

    <div *ngIf="isResumeRouteActive" class="header-intro header-intro-resume theme-bg-primary text-white py-5">
        <div class="container position-relative">
            <h2 class="page-heading mb-3">Resume</h2>
        </div>
    </div>
</header>
<router-outlet></router-outlet>
<footer class="footer text-light text-center py-2">
    <app-footer></app-footer>
</footer>